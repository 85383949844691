import './hero-banner.component.css'
import API from '../../../api'
import { useLayoutEffect } from 'react'
import Utils from '../../../utils'
import { gsap } from 'gsap'
import { useNavigate } from 'react-router-dom'

const HeroBannerComponent = (props) => {
  const {
    ctaAction,
    ctaActionLink,
    ctaActionLabel,
    useBrandColorForLink,
    includeCta,
    includeSecondaryCta,
    includeRestaurantLogo,
    includeSvgLogo,
    includeLightbox,
    customLightboxOpacity,
    image,
    includeLink,
    link,
    svgLogo,
    svgLogoStyle,
    restaurant,
    useWidescreenAspectRatio,
    setVerticalPosition,
    verticalPosition,
    secondaryCtaAction,
    secondaryCtaActionLink,
    secondaryCtaActionLabel,
  } = props

  const navigate = useNavigate()

  const getClassName = () => {
    let className = 'prg-hero-banner-container'

    if (!useWidescreenAspectRatio) {
      className += ' use-natural-ratio'
    }

    if (includeLink && link) {
      className += ' actionable'
    }

    return className
  }

  const getSrcUrl = () => {
    if (!image || !image.url) {
      return ''
    }

    if (!useWidescreenAspectRatio) {
      return image.url.replace(/ /g, '%20')
    }

    if (image.sizes?.sixteenByNineLarge?.url) {
      return image.sizes.sixteenByNineLarge.url.replace(/ /g, '%20')
    } else if (image.sizes?.sixteenByNineMedium?.url) {
      return image.sizes.sixteenByNineMedium.url.replace(/ /g, '%20')
    }

    return image.url.replace(/ /g, '%20')
  }

  // Start animations
  useLayoutEffect(() => {
    const buttonEl = document.querySelector('.button-container')

    const doAnimations = () => {
      const svgEl = document.querySelector(
        '.prg-hero-banner-container .svg-container',
      )
      if (svgEl) {
        gsap.fromTo(
          svgEl,
          {
            yPercent: 100,
            scale: 0.5,
            opacity: 0,
          },
          {
            yPercent: 0,
            scale: 1,
            opacity: 1,
            duration: 1,
            ease: 'elastic.out(1, 0.8)',
            delay: 0.3,
          },
        )
      }

      const bannerImageEl = document.querySelector('.prg-hero-banner-image')
      if (bannerImageEl) {
        gsap.fromTo(
          bannerImageEl,
          {
            yPercent: 20,
            opacity: 0,
          },
          {
            yPercent: 0,
            opacity: 1,
            duration: 1,
            ease: 'elastic.out(1, 0.8)',
            delay: 0.3,
          },
        )

        // Scroll trigger for bg image
        const scrollParams = {
          once: true,
          trigger: '.prg-hero-banner-container',
          start: 'top top',
          end: '+=170',
          scrub: 2,
        }

        gsap.fromTo(
          bannerImageEl,
          {
            backgroundPositionY: '0%',
          },
          {
            backgroundPositionY:
              setVerticalPosition && verticalPosition
                ? verticalPosition
                : '50%',
            scrollTrigger: scrollParams,
          },
        )
      }

      if (buttonEl) {
        gsap.fromTo(
          buttonEl,
          {
            transform: 'translate(-50%, 100px)',
            opacity: 0,
          },
          {
            transform: 'translate(-50%, 0)',
            opacity: 1,
            duration: 1,
            ease: 'elastic.out(1, 0.8)',
            delay: 0.5,
          },
        )
      }
    }

    if (!restaurant.styles.disableParallax) {
      setTimeout(doAnimations)
    } else {
      if (buttonEl) {
        gsap.set(buttonEl, {
          transform: 'translate(-50%, 0)',
          opacity: 1,
        })
      }
    }
  }, [setVerticalPosition, verticalPosition, restaurant.styles])

  const handleDivClick = () => {
    if (includeLink && link) {
      Utils.handleAction(link, restaurant, navigate)
    }
  }

  return (
    <div className={getClassName()} onClick={handleDivClick}>
      {useWidescreenAspectRatio ? (
        <div
          className="prg-hero-banner-image"
          style={{
            backgroundImage: `url('${API.getMediaAssetUrl(getSrcUrl())}')`,
            backgroundPositionY:
              setVerticalPosition && verticalPosition ? verticalPosition : '0%',
          }}
        />
      ) : (
        <img src={API.getMediaAssetUrl(getSrcUrl())} alt={image?.altText} />
      )}
      {includeLightbox && (
        <div
          className="prg-hero-banner-lightbox"
          style={{
            opacity: customLightboxOpacity ? customLightboxOpacity : 0.2,
          }}
        />
      )}
      {includeRestaurantLogo && (
        <div className="svg-container">{restaurant.icons.logoIcon}</div>
      )}
      {includeSvgLogo && svgLogo && !includeRestaurantLogo && (
        <div
          className={`svg-container style-${svgLogoStyle}`}
          dangerouslySetInnerHTML={{ __html: svgLogo }}
        />
      )}
      {(includeCta || includeSecondaryCta) && (
        <div className="button-container">
          {/* Primary CTA */}
          {includeCta && (
            <>
              {ctaAction !== 'link' && (
                <button
                  onClick={() =>
                    Utils.handleAction(`action:${ctaAction}`, restaurant)
                  }
                  className="prg-primary-btn"
                >
                  {ctaAction === 'orderOnline' ? 'Order Online' : 'View Menu'}
                </button>
              )}
              {ctaAction === 'link' && ctaActionLabel && ctaActionLink && (
                <button
                  onClick={() =>
                    Utils.handleAction(ctaActionLink, restaurant, navigate)
                  }
                  className={
                    useBrandColorForLink
                      ? 'prg-primary-btn use-brand-color'
                      : 'prg-primary-btn'
                  }
                >
                  {ctaActionLabel}
                </button>
              )}
            </>
          )}

          {/* Secondary CTA */}
          {includeSecondaryCta && secondaryCtaAction && (
            <>
              {secondaryCtaAction !== 'link' && (
                <button
                  onClick={() =>
                    Utils.handleAction(
                      `action:${secondaryCtaAction}`,
                      restaurant,
                    )
                  }
                  className="prg-primary-btn outline"
                >
                  {secondaryCtaAction === 'orderOnline'
                    ? 'Order Online'
                    : 'View Menu'}
                </button>
              )}
              {secondaryCtaAction === 'link' &&
                secondaryCtaActionLabel &&
                secondaryCtaActionLink && (
                  <button
                    onClick={() =>
                      Utils.handleAction(
                        secondaryCtaActionLink,
                        restaurant,
                        navigate,
                      )
                    }
                    className={
                      useBrandColorForLink
                        ? 'prg-primary-btn outline use-brand-color'
                        : 'prg-primary-btn outline'
                    }
                  >
                    {secondaryCtaActionLabel}
                  </button>
                )}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default HeroBannerComponent
