import { useContext, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import API from '../../../api'
import { RestaurantContext } from '../../../prg'
import Utils from '../../../utils'
import HeroBannerComponent from '../../components/hero-banner/hero-banner.component'
import LocationSelectorComponent from '../../components/location-selector/location-selector.component'
import './page.page.css'
import MenuSelectorComponent from '../../components/menu-selector/menu-selector.component'
import CallToActionRowComponent from '../../components/call-to-action-row/call-to-action-row.component'
import ContentLineBreakComponent from '../../components/content-line-break/content-line-break.component'
import CallToActionBlockComponent from '../../components/call-to-action-block/call-to-action-block.component'
import LocationMapImageBlockComponent from '../../components/location-map-image-block/location-map-image-block.component'
import ImageGridComponent from '../../components/image-grid/image-grid.component'
import TextHeroComponent from '../../components/text-hero/text-hero.component'
import HeaderComponent from '../../components/header/header.component'
import FooterComponent from '../../components/footer/footer.component'
import MenuListComponent from '../../components/menu-list/menu-list.component'
import FlexPromoComponent from '../../components/flex-promo/flex-promo.component'
import EventsListComponent from '../../components/events-list/events-list.component'
import CallToActionButtonGroupComponent from '../../components/call-to-action-button-group/call-to-action-button-group.component'
import { Helmet } from 'react-helmet'
import useScrollTop from '../../../hooks/useScrollTop'
import ScrollerContentComponent from '../../components/scroller-content/scroller-content.component'
import ThreePieceContentComponent from '../../components/three-piece-content/three-piece-content.component'
import PeopleGridComponent from '../../components/people-grid/people-grid.component'
import BlockContainerComponent from '../../components/block-container/block-container.component'
import PressGridComponent from '../../components/press-grid/press-grid.component'
import CareersListComponent from '../../components/careers-list/careers-list.component'
import ShopItemsGridComponent from '../../components/shop-items-grid/shop-items-grid.component'
import ProductDetailModalComponent from '../../components/product-detail-modal/product-detail-modal.component'
import PersonModalComponent from '../../components/person-modal/person-modal.component'
import ToastComponent from '../../components/toast/toast.component'
import { LOCAL_STORAGE_CART_KEY_PREFIX } from '../../../constants'
import HorizontalListComponent from '../../components/horizontal-list/horizontal-list.component'
import PeopleListComponent from '../../components/people-list/people-list.component'
import SvgBlockComponent from '../../components/svg-block/svg-block.component'
import QuoteBlockComponent from '../../components/quote-block/quote-block.component'
import OrderPromptComponent from '../../components/order-prompt/order-prompt.component'

const Page = (props) => {
  const { slug: slugFromProps } = props
  const Restaurant = useContext(RestaurantContext)
  const [pageContent, setPageContent] = useState()
  const [notFound, setNotFound] = useState()
  const [loading, setLoading] = useState(true)
  const [shopProduct, setShopProduct] = useState()
  const [selectedPerson, setSelectedPerson] = useState()
  const [showOrderPrompt, setShowOrderPrompt] = useState()
  const timerRef = useRef()

  // Get slug & load page via API
  const { slug: slugFromParams } = useParams()
  const slug = slugFromProps || slugFromParams

  // Fetch page content
  useEffect(() => {
    if (!slug) return

    const fetchPageContent = async () => {
      let status = 200

      try {
        console.log('fetching page: ', slug)
        setLoading(true)
        setNotFound(false)

        // Get id of slug in restaurant pages array
        const id = Utils.getIdFromSlug(Restaurant, slug)
        if (id) {
          const res = await API.getContent('pages', id)
          setPageContent(res)
        } else {
          console.log('page not found...')
          setNotFound(true)
          status = 404
        }
      } catch (e) {
        console.log('page not found...')
        console.error(e)
        setNotFound(true)
        status = 404
      } finally {
        setLoading(false)
        setTimeout(() => {
          document.dispatchEvent(
            new CustomEvent('EVENTS.SHOW_LOADER', { detail: false }),
          )

          if (window.onCaptureReady) {
            setTimeout(() => window.onCaptureReady(status), 4000)
          }
        }, 250)
      }
    }

    const maybeSubscribeToLivePreview = () => {
      if (Utils.isProd() || window.location === window.parent.location) return

      // Add event listener for live preview
      console.log('adding live preview functionality...')
      window.addEventListener('message', maybeReloadContent)

      const windowToPostTo = window?.opener || window?.parent
      windowToPostTo?.postMessage(
        {
          ready: true,
          type: 'payload-live-preview',
        },
        API.getRootUrl(),
      )
    }

    const maybeReloadContent = async (message) => {
      if (message.origin !== API.getRootUrl()) return

      // Debounce + set
      if (timerRef.current) {
        clearTimeout(timerRef.current)
        timerRef.current = null
      }

      timerRef.current = setTimeout(async () => {
        const data = message.data?.data
        if (data) {
          console.log('content updated...', data)
          // Fetch media as needed
          for (let i = 0; i < data.content.length; i++) {
            const c = data.content[i]
            if (c?.image) {
              c.image = await API.getContent('media', c.image)
            } else if (c?.promos?.length > 0) {
              for (let j = 0; j < c.promos.length; j++) {
                if (c.promos[j].image) {
                  c.promos[j].image = await API.getContent(
                    'media',
                    c.promos[j].image,
                  )
                }
              }
            } else if (c?.people?.length > 0) {
              for (let j = 0; j < c.people.length; j++) {
                if (c.people[j].photo) {
                  c.people[j].photo = await API.getContent(
                    'media',
                    c.people[j].photo,
                  )
                }
              }
            } else if (c?.images?.length > 0) {
              for (let j = 0; j < c.images.length; j++) {
                if (c.images[j].image) {
                  c.images[j].image = await API.getContent(
                    'media',
                    c.images[j].image,
                  )
                }
              }
            }
          }

          setPageContent(data)
        }
      }, 250)
    }

    fetchPageContent()
    maybeSubscribeToLivePreview()

    return () => {
      window.removeEventListener('message', maybeReloadContent)

      if (timerRef.current) {
        clearTimeout(timerRef.current)
        timerRef.current = null
      }
    }
  }, [slug, Restaurant])

  const getContentBlock = (block) => {
    if (block.hide) return null

    switch (block.blockType) {
      default: {
        console.error('Unsupported content block found: ', block.blockType)
        return null
      }
      case 'HeroBanner': {
        return (
          <HeroBannerComponent
            key={block.id}
            {...block}
            restaurant={Restaurant}
          />
        )
      }
      case 'LocationPicker': {
        return (
          <LocationSelectorComponent
            key={block.id}
            {...block}
            restaurant={Restaurant}
          />
        )
      }
      case 'MenuSelector': {
        return (
          <MenuSelectorComponent
            key={block.id}
            {...block}
            restaurant={Restaurant}
          />
        )
      }
      case 'CallToActionRow': {
        return (
          <CallToActionRowComponent
            key={block.id}
            {...block}
            restaurant={Restaurant}
          />
        )
      }
      case 'CallToActionBlock': {
        return (
          <CallToActionBlockComponent
            key={block.id}
            {...block}
            restaurant={Restaurant}
          />
        )
      }
      case 'CallToActionButtonGroup': {
        return (
          <CallToActionButtonGroupComponent
            key={block.id}
            {...block}
            restaurant={Restaurant}
          />
        )
      }
      case 'ContentLineBreak': {
        return (
          <ContentLineBreakComponent
            key={block.id}
            {...block}
            restaurant={Restaurant}
          />
        )
      }
      case 'LocationMapImageBlock': {
        return (
          <LocationMapImageBlockComponent
            key={block.id}
            {...block}
            restaurant={Restaurant}
          />
        )
      }
      case 'ImageGrid': {
        return (
          <ImageGridComponent
            key={block.id}
            {...block}
            restaurant={Restaurant}
          />
        )
      }
      case 'TextHero': {
        return (
          <TextHeroComponent
            key={block.id}
            {...block}
            restaurant={Restaurant}
          />
        )
      }
      case 'MenuList': {
        return (
          <MenuListComponent
            key={block.id}
            {...block}
            restaurant={Restaurant}
          />
        )
      }
      case 'FlexPromo': {
        return (
          <FlexPromoComponent
            key={block.id}
            {...block}
            restaurant={Restaurant}
          />
        )
      }
      case 'EventsList': {
        return (
          <EventsListComponent
            key={block.id}
            {...block}
            restaurant={Restaurant}
          />
        )
      }
      case 'SvgBlock': {
        return (
          <SvgBlockComponent
            key={block.id}
            {...block}
            restaurant={Restaurant}
          />
        )
      }
      case 'ScrollerContent': {
        return (
          <ScrollerContentComponent
            key={block.id}
            {...block}
            restaurant={Restaurant}
          />
        )
      }
      case 'ThreePieceContent': {
        return (
          <ThreePieceContentComponent
            key={block.id}
            {...block}
            restaurant={Restaurant}
          />
        )
      }
      case 'PeopleGrid': {
        return (
          <PeopleGridComponent
            key={block.id}
            {...block}
            restaurant={Restaurant}
          />
        )
      }
      case 'PeopleList': {
        return (
          <PeopleListComponent
            key={block.id}
            {...block}
            restaurant={Restaurant}
          />
        )
      }
      case 'BlockContainer': {
        return (
          <BlockContainerComponent
            key={block.id}
            {...block}
            restaurant={Restaurant}
          />
        )
      }
      case 'PressGrid': {
        return (
          <PressGridComponent
            key={block.id}
            {...block}
            restaurant={Restaurant}
          />
        )
      }
      case 'CareersList': {
        return (
          <CareersListComponent
            key={block.id}
            {...block}
            restaurant={Restaurant}
          />
        )
      }
      case 'ShopItemsGrid': {
        return (
          <ShopItemsGridComponent
            key={block.id}
            {...block}
            restaurant={Restaurant}
          />
        )
      }
      case 'HorizontalList': {
        return (
          <HorizontalListComponent
            key={block.id}
            {...block}
            restaurant={Restaurant}
          />
        )
      }
      case 'QuoteBlock': {
        return (
          <QuoteBlockComponent
            key={block.id}
            {...block}
            restaurant={Restaurant}
          />
        )
      }
    }
  }

  useScrollTop()

  // Listen for a shop product
  useEffect(() => {
    const handleShowProduct = (e) => {
      setShopProduct(e.detail)
    }

    document.addEventListener('EVENTS.SHOW_SHOP_PRODUCT', handleShowProduct)

    return () =>
      document.removeEventListener(
        'EVENTS.SHOW_SHOP_PRODUCT',
        handleShowProduct,
      )
  }, [])

  const handleCloseProductDetail = () => {
    window.history.replaceState({}, null, `/${slug}`)
    setShopProduct(false)
  }

  // Listen for a selected person
  useEffect(() => {
    const handleShowPerson = (e) => {
      setSelectedPerson(e.detail)
    }

    document.addEventListener('EVENTS.SHOW_PERSON', handleShowPerson)

    return () =>
      document.removeEventListener('EVENTS.SHOW_PERSON', handleShowPerson)
  }, [])

  const handleClosePerson = () => {
    setSelectedPerson(false)
  }

  // Listen for order prompt
  useEffect(() => {
    const handleShowOrderPrompt = () => {
      setShowOrderPrompt(true)
    }

    document.addEventListener('EVENTS.SHOW_ORDER_PROMPT', handleShowOrderPrompt)

    return () =>
      document.removeEventListener(
        'EVENTS.SHOW_ORDER_PROMPT',
        handleShowOrderPrompt,
      )
  }, [])

  const handleCloseOrderPrompt = () => {
    setShowOrderPrompt(false)
  }

  // Fetch cart on load
  useEffect(() => {
    const fetchCart = async (cartKey) => {
      try {
        const cartId = window.localStorage.getItem(cartKey)
        console.log('fetching cart...')
        const res = await API.postContent('cart/get', { cartId })

        if (!res.items) {
          // Clear cart
          window.localStorage.removeItem(cartKey)
          document.dispatchEvent(
            new CustomEvent('EVENTS.CART_UPDATE', { detail: 0 }),
          )
          return
        }

        document.dispatchEvent(
          new CustomEvent('EVENTS.CART_UPDATE', { detail: res.items.length }),
        )
      } catch (e) {
        // fail silently
        console.error(e)
      }
    }

    const cartKey = `${LOCAL_STORAGE_CART_KEY_PREFIX}${Restaurant.slug}`
    if (
      window.localStorage.getItem(cartKey) !== undefined &&
      window.localStorage.getItem(cartKey) !== null
    ) {
      fetchCart(cartKey)
    }
  }, [Restaurant])

  return (
    <>
      <Helmet>
        <title>
          {pageContent?.seo?.title ? pageContent.seo.title : Restaurant.name}
        </title>
        <meta
          property="og:description"
          content={pageContent?.seo?.description}
        />
        <meta property="description" content={pageContent?.seo?.description} />
        <meta property="og:site_name" content={Restaurant.name} />
        <meta property="og:title" content={pageContent?.seo?.title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={
            pageContent?.seo?.previewImage?.url
              ? API.getMediaAssetUrl(pageContent.seo.previewImage.url)
              : null
          }
        />
        <meta
          property="og:url"
          content={`${window.location.host}/${pageContent?.slug}`}
        />
        <link rel="icon" href={`/${Restaurant.slug}/favicon.ico`} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`/${Restaurant.slug}/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`/${Restaurant.slug}/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`/${Restaurant.slug}/favicon-16x16.png`}
        />
        <link rel="manifest" href={`/${Restaurant.slug}/site.webmanifest`} />

        {!loading && !notFound && pageContent?.seo?.structuredData && (
          <script type="application/ld+json">
            {JSON.stringify(pageContent.seo.structuredData)}
          </script>
        )}
        {!loading && notFound && (
          <meta name="prerender-status-code" content="404" />
        )}
      </Helmet>
      <ToastComponent />
      {!Restaurant.styles.disableHeader && (
        <HeaderComponent restaurant={Restaurant} />
      )}
      {shopProduct && (
        <ProductDetailModalComponent
          {...shopProduct}
          restaurantSlug={Restaurant.slug}
          onClose={handleCloseProductDetail}
        />
      )}
      {selectedPerson && (
        <PersonModalComponent
          person={selectedPerson}
          onClose={handleClosePerson}
        />
      )}
      {showOrderPrompt && (
        <OrderPromptComponent
          onClose={handleCloseOrderPrompt}
          restaurant={Restaurant}
        />
      )}
      <div className="prg-page-wrapper" id={`page-${slug}`}>
        <div
          className={
            Restaurant.styles.disableHeader
              ? 'prg-page-container sans-header'
              : Restaurant.headerItems.includeAlertMessage &&
                Restaurant.headerItems.alertMessage
              ? 'prg-page-container with-alert'
              : 'prg-page-container'
          }
        >
          {pageContent?.content?.map((c) => getContentBlock(c))}
          {!loading && notFound && (
            <div className="prg-error">
              <h3>Uh oh. We looked everywhere but couldn't find that page.</h3>
              <p>
                Please try again or go back to our{' '}
                <Link to="/" onClick={() => Utils.maybeShowLoader('/')}>
                  home page
                </Link>
              </p>
            </div>
          )}
        </div>
        <FooterComponent restaurant={Restaurant} />
      </div>
    </>
  )
}

export default Page
