import {
  GIFT_CARD_URL,
  PROD_SLUG_MAPS,
  VALID_PROD_DOMAINS,
  VALID_SUBDOMAINS,
} from './constants'
import { emailIcon, fbIcon, igIcon, twitterIcon } from './shared/icons'
import { gsap } from 'gsap'

class Utils {
  static getIdFromSlug = (context, slug) => {
    const page = context?.pages?.find((p) => p.slug === slug)
    return page?.id
  }

  static maybeShowLoader = (slug) => {
    const saniSlug = slug.indexOf('/') > -1 ? slug : `/${slug}`
    const curSlug = window.location.pathname
    const loading = saniSlug !== curSlug
    document.dispatchEvent(
      new CustomEvent('EVENTS.SHOW_LOADER', { detail: loading }),
    )
  }

  static getIconFromName = (iconName) => {
    switch (iconName) {
      default: {
        return null
      }
      case 'twitter': {
        return twitterIcon
      }
      case 'facebook': {
        return fbIcon
      }
      case 'instagram': {
        return igIcon
      }
      case 'email': {
        return emailIcon
      }
    }
  }

  static createDeferred = () => {
    const deferred = {}
    const promise = new Promise((resolve, reject) => {
      deferred.resolve = resolve
      deferred.reject = reject
    })

    deferred.promise = promise
    return deferred
  }

  static calculateDistanceBetweenPoints = (p1, p2) => {
    const R = 3958.8 // Radius of the Earth in miles
    const rlat1 = p1.lat * (Math.PI / 180)
    const rlat2 = p2.lat * (Math.PI / 180)
    const difflat = rlat2 - rlat1
    const difflon = (p2.long - p1.long) * (Math.PI / 180)

    const d =
      2 *
      R *
      Math.asin(
        Math.sqrt(
          Math.sin(difflat / 2) * Math.sin(difflat / 2) +
            Math.cos(rlat1) *
              Math.cos(rlat2) *
              Math.sin(difflon / 2) *
              Math.sin(difflon / 2),
        ),
      )
    return d
  }

  static handleAction = (action, restaurant, nav) => {
    if (action?.indexOf('http') > -1 || action?.indexOf('mailto:') > -1) {
      window.open(action, '_blank')
      return false
    } else if (action?.indexOf('action:') > -1) {
      if (action === 'action:giftcards') {
        // if (restaurant.selectedLocation.giftCardUrl) {
        //   window.open(restaurant.selectedLocation.giftCardUrl, '_blank')
        // }
        // Universal gift card
        window.open(GIFT_CARD_URL, '_blank')
      } else if (action === 'action:reservation') {
        if (
          restaurant.selectedLocation.includeReservationLink &&
          restaurant.selectedLocation.reservationLink
        ) {
          window.open(restaurant.selectedLocation.reservationLink, '_blank')
        }
      } else if (action === 'action:orderOnline') {
        document.dispatchEvent(new CustomEvent('EVENTS.SHOW_ORDER_PROMPT'))
      } else if (action === 'action:scrollDown') {
        gsap.to('html', {
          scrollTo: 500,
          duration: 2,
          ease: 'Power2.easeInOut',
        })
      } else if (action === 'action:menu') {
        gsap.to('html', {
          scrollTo: '.prg-menu-list',
          duration: 1,
          ease: 'Power2.easeInOut',
        })
      } else if (action === 'action:careers') {
        gsap.to('html', {
          scrollTo: '.prg-careers-list',
          duration: 1,
          ease: 'Power2.easeInOut',
        })
      } else if (action.indexOf('action:careerFilter=') > -1) {
        // Dispatch filter
        const filter = action.split('action:careerFilter=')[1]
        document.dispatchEvent(
          new CustomEvent('EVENTS.FILTER_CAREERS', { detail: filter }),
        )
        gsap.to('html', {
          scrollTo: '.prg-careers-list',
          duration: 1,
          ease: 'Power2.easeInOut',
          delay: 0.5,
        })
      }
      return false
    }

    this.maybeShowLoader(action)
    nav(action)
    return true
  }

  static isProd = () => {
    const host = window.location.hostname
    return VALID_PROD_DOMAINS.indexOf(host) > -1
  }

  static getSlugFromDomain = () => {
    const host = window.location.hostname
    let slug = 'hero'

    // Check for prod
    if (this.isProd()) {
      slug = PROD_SLUG_MAPS[host]
    } else {
      // Check for dev subdomain
      const subdomain = host.indexOf('.') > -1 ? host.split('.')[0] : host
      slug = VALID_SUBDOMAINS.indexOf(subdomain) > -1 ? subdomain : slug
    }

    return slug
  }

  static resetGsapProps = (elClass, props) => {
    if (document.querySelectorAll(elClass).length > 0) {
      gsap.set(elClass, props)
    }
  }

  static addGoogleTags = (id) => {
    const tagId = '__prg-google-gtm'
    const existing = document.getElementById(tagId)
    if (existing) return

    console.log('__prg-google-gtm', id)
    const el = document.createElement('script')
    el.id = tagId
    el.src = `https://www.googletagmanager.com/gtag/js?id=${id}`
    el.setAttribute('async', true)
    document.head.appendChild(el)

    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }

    gtag('js', new Date())
    gtag('config', id)
  }
}

export default Utils
